const pages = {
  landings: [
    // {
    //   groupTitle: 'Services',
    //   pages: [
    //     {
    //       title: 'Coworking',
    //       href: '/coworking',
    //     },
    //     {
    //       title: 'Rental',
    //       href: '/rental',
    //     },
    //     {
    //       title: 'Job Listing',
    //       href: '/job-listing',
    //     },
    //     {
    //       title: 'Catapult',
    //       href: '/catapult',
    //     },
    //     {
    //       title: 'E-commerce',
    //       href: '/e-commerce',
    //     },
    //     {
    //       title: 'Expo',
    //       href: '/expo',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Web',
    //   pages: [
    //     {
    //       title: 'Marketing',
    //       href: '/',
    //     },
    //     {
    //       title: 'Overview',
    //       href: '/home',
    //     },
    //     {
    //       title: 'Basic',
    //       href: '/web-basic',
    //     },
    //     {
    //       title: 'Service',
    //       href: '/service',
    //     },
    //     {
    //       title: 'Startup',
    //       href: '/startup',
    //     },
    //     {
    //       title: 'Enterprise',
    //       href: '/enterprise',
    //     },
    //     {
    //       title: 'Cloud Hosting',
    //       href: '/cloud-hosting',
    //     },
    //     {
    //       title: 'Agency',
    //       href: '/agency',
    //     },
    //     {
    //       title: 'Design Company',
    //       href: '/design-company',
    //     },
    //     {
    //       title: 'Logistics',
    //       href: '/logistics',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Apps',
    //   pages: [
    //     {
    //       title: 'Desktop App',
    //       href: '/desktop-app',
    //     },
    //     {
    //       title: 'Mobile App',
    //       href: '/mobile-app',
    //     },
    //   ],
    // },
  ],
  secondary: [
    // {
    //   groupTitle: 'Career',
    //   pages: [
    //     {
    //       title: 'Lising',
    //       href: '/career-listing',
    //     },
    //     {
    //       title: 'Minimal',
    //       href: '/career-listing-minimal',
    //     },
    //     {
    //       title: 'Opening',
    //       href: '/career-opening',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Help center',
    //   pages: [
    //     {
    //       title: 'Overview',
    //       href: '/help-center',
    //     },
    //     {
    //       title: 'Article',
    //       href: '/help-center-article',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Company',
    //   pages: [
    //     {
    //       title: 'About',
    //       href: '/about',
    //     },
    //     {
    //       title: 'About-cover',
    //       href: '/about-side-cover',
    //     },
    //     {
    //       title: 'Pricing',
    //       href: '/pricing',
    //     },
    //     {
    //       title: 'Terms',
    //       href: '/company-terms',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Contact',
    //   pages: [
    //     {
    //       title: 'Reach View',
    //       href: '/contact-page',
    //     },
    //     {
    //       title: 'Sidebar Map',
    //       href: '/contact-sidebar-map',
    //     },
    //     {
    //       title: 'Cover',
    //       href: '/contact-page-cover',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Blog',
    //   pages: [
    //     {
    //       title: 'Newsroom',
    //       href: '/blog-newsroom',
    //     },
    //     {
    //       title: 'Reach View',
    //       href: '/blog-reach-view',
    //     },
    //     {
    //       title: 'Search',
    //       href: '/blog-search',
    //     },
    //     {
    //       title: 'Article',
    //       href: '/blog-article',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Portfolio',
    //   pages: [
    //     {
    //       title: 'Basic',
    //       href: '/portfolio-page',
    //     },
    //     {
    //       title: 'Masonry',
    //       href: '/portfolio-masonry',
    //     },
    //     {
    //       title: 'Grid View',
    //       href: '/portfolio-grid',
    //     },
    //     {
    //       title: 'Parallax',
    //       href: '/agency',
    //     },
    //   ],
    // },
  ],
  account: [
    // {
    //   groupTitle: 'Settings',
    //   pages: [
    //     {
    //       title: 'General',
    //       href: '/account-general',
    //     },
    //     {
    //       title: 'Security',
    //       href: '/account-security',
    //     },
    //     {
    //       title: 'Notifications',
    //       href: '/account-notifications',
    //     },
    //     {
    //       title: 'Billing',
    //       href: '/account-billing',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Error',
    //   pages: [
    //     {
    //       title: 'Simple',
    //       href: '/not-found',
    //     },
    //     {
    //       title: 'Cover',
    //       href: '/not-found-cover',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Sign up',
    //   pages: [
    //     {
    //       title: 'Simple',
    //       href: '/signup-simple',
    //     },
    //     {
    //       title: 'Cover',
    //       href: '/signup-cover',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Sign in',
    //   pages: [
    //     {
    //       title: 'Simple',
    //       href: '/signin-simple',
    //     },
    //     {
    //       title: 'Cover',
    //       href: '/signin-cover',
    //     },
    //   ],
    // },
    // {
    //   groupTitle: 'Password reset',
    //   pages: [
    //     {
    //       title: 'Simple',
    //       href: '/password-reset-simple',
    //     },
    //     {
    //       title: 'Cover',
    //       href: '/password-reset-cover',
    //     },
    //   ],
    // },
  ],
};

export default pages;
