import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import Typography from '@mui/material/Typography';

// import { NavItem, ThemeModeToggler } from './components';

// import CatapultLogo from 'svg/catapult/CatapultLogo';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    landings: Array<PageItem>;
    secondary: Array<PageItem>;
    account: Array<PageItem>;
  };
}

const Topbar = ({ onSidebarOpen }: Props): JSX.Element => {
  const theme = useTheme();
  // const { mode } = theme.palette;
  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   account: accountPages,
  // } = pages;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
    >
      <Box
        display="flex"
        component="a"
        href="/"
        title="Catapult"
        width={{ xs: 140, md: 170 }}
      >
        <Box
          component="img"
          src="/images/Catapult logo 2.png"
          alt="Catapult Logo"
          title="Catapult Logo"
          width="100%"
        />
        {/* <Box marginBottom={1} marginLeft={2}>
          <Typography
            color={'primary'}
            component={'span'}
            variant="h4"
            sx={{ fontWeight: 700 }}
          >
            Catapult
          </Typography>
        </Box> */}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center">
        {/* <Box>
          <NavItem
            title={'Landings'}
            id={'landing-pages'}
            items={landingPages}
          />
        </Box>
        <Box marginLeft={3}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
          />
        </Box>
        <Box marginLeft={3}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
          />
        </Box>
        <Box marginLeft={3}>
          <Link
            underline="none"
            component="a"
            href="/docs/introduction"
            color="text.primary"
          >
            Documentation
          </Link>
        </Box> */}
        {/* <Box marginLeft={3}>
          <ThemeModeToggler />
        </Box> */}

        <Box
          marginLeft={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="caption"
            color="text.secondary"
            component="p"
            marginBottom="2px"
          >
            Already have Catapult?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://app.catapult.stream/accounts/login"
            size="large"
          >
            Sign in
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems="center">
        {/* <Box marginRight={2}>
          <ThemeModeToggler />
        </Box> */}
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant="outlined"
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
