import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { YouTube, Facebook, Twitter } from '@mui/icons-material';

const Footer = (): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginTop={1} marginRight={2}>
            <Link
              underline="none"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCuQ5Ve1zCKbi2b18vd7KGjQ"
              color="text.primary"
              variant="subtitle2"
              display="flex"
              alignItems="center"
              whiteSpace="pre-wrap"
            >
              <YouTube /> YouTube
            </Link>
          </Box>
          <Box marginTop={1} marginRight={2}>
            <Link
              underline="none"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/catapult4video"
              color="text.primary"
              variant="subtitle2"
              display="flex"
              alignItems="center"
              whiteSpace="pre-wrap"
            >
              <Facebook /> Facebook
            </Link>
          </Box>
          <Box marginTop={1}>
            <Link
              underline="none"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/tryCatapult"
              color="text.primary"
              variant="subtitle2"
              display="flex"
              alignItems="center"
              whiteSpace="pre-wrap"
            >
              <Twitter /> Twitter
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="subtitle2"
          color="text.secondary"
          gutterBottom
        >
          &copy; {new Date().getFullYear()} SoopahGenius, Inc. All rights
          reserved.
        </Typography>
        <Typography
          align="center"
          variant="caption"
          color="text.secondary"
          component="p"
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
